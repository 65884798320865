@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;500;700&display=swap');

html, body, #root {
  margin: 0px;
  padding: 0px;
  font-family: sans-serif;
  height: 100%;
  overflow: auto;
  overscroll-behavior-x: none;
  overscroll-behavior-y: none;
}

.StandardHiylloInput {
  background-color: #eee;
  padding: 10px;
  border-radius: 10px;
  border: none;
  min-width: min(200px, 75vw);
}